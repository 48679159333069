import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import './style.css';

function Tooltip(props) {
  let className = 'r-tooltip';
  const contentClassName = 'r-tooltip__content';

  if (props.className) {
    className += ` ${props.className}`;
  }

  return (
    <ReactTooltip className={className} effect="solid" delayShow={100} place="bottom" {...props} variant="success" show>
      <div className={contentClassName}>{props.children}</div>
    </ReactTooltip>
  );
}

Tooltip.propTypes = {
  children: PropTypes.any,
  id: PropTypes.string,
  className: PropTypes.string
};

export default Tooltip;
