import React, { ChangeEventHandler, FocusEventHandler, ReactElement } from 'react';
import './style.css';

interface Props {
  value?: any;
  placeholder?: string;
  onChange: ChangeEventHandler<HTMLTextAreaElement>;
  onBlur?: FocusEventHandler<HTMLTextAreaElement>;
}

function TextAreaBaseComponent({ value, placeholder, onChange, onBlur }: Props): ReactElement {
  return (
    <textarea
      className="textarea"
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      onBlur={onBlur}
      aria-label="Text area"
    />
  );
}

export default TextAreaBaseComponent;
