import { ToastContainer as Toaster, toast, Flip } from 'react-toastify'; // https://github.com/fkhadra/react-toastify

Toaster.Position = toast.POSITION;

export const TOAST_POSITION = toast.POSITION;

export function isToastActive(id) {
  return toast.isActive(id);
}

export function eatToast(id) {
  return toast.dismiss(id);
}

export function raiseToast(toastComponent, opts = {}) {
  const options = {
    autoClose: opts.autoClose === false ? false : opts.autoClose || 9000,
    closeButton: false,
    closeOnClick: opts.closeOnClick,
    pauseOnHover: true,
    pauseOnFocusLoss: false,
    position: opts.position || toast.POSITION.TOP_RIGHT,
    hideProgressBar: opts.autoClose === false,
    transition: Flip
  };

  return toast(toastComponent, options);
}

export function updateToast(toastId, toastComponent, opts = {}) {
  const options = {
    render: toastComponent,
    ...opts
  };

  return toast.update(toastId, options);
}

export default Toaster;
