// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.textarea {
  padding: 20px;
  border: 1px solid var(--color-grayscale-5);
  width: auto;
  height: 200px;
  outline: none;
}
`, "",{"version":3,"sources":["webpack://./app/components/TextArea/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,0CAA0C;EAC1C,WAAW;EACX,aAAa;EACb,aAAa;AACf","sourcesContent":[".textarea {\n  padding: 20px;\n  border: 1px solid var(--color-grayscale-5);\n  width: auto;\n  height: 200px;\n  outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
