import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import './style.css';

function TextInput(props) {
  // State.
  const [state, setState] = useState({
    selected: false,
    value: props.value || props.value === 0 ? props.value : '',
    submitted: false
  });

  const prevPropsValue = useRef(props.value);

  if (props.value !== prevPropsValue.current) {
    prevPropsValue.current = props.value;
    state.value = props.value ? props.value : '';
  }

  const inputRef = useRef();

  useEffect(() => {
    if (props.autoFocus && inputRef.current) {
      inputRef.current.select();
    }
  }, []);

  const handleChange = (event) => {
    const val = event.target.value;

    setState((prevState) => ({
      ...prevState,
      value: val,
      submitted: false
    }));

    if (props.onChange) {
      props.onChange(val, props, event);
    }
  };

  const handleSelect = () => {
    setState((prevState) => ({
      ...prevState,
      selected: true
    }));
  };

  const handleBlur = () => {
    setState((prevState) => ({
      ...prevState,
      selected: false,
      value: props.clearOnBlur || props.clearOnSubmit ? '' : prevState.value,
      submitted: true
    }));

    let stateValue = state.value;
    let propsValue = props.value;

    if (!state.value && !props.value) {
      stateValue = null;
      propsValue = null;
    }

    if (props.onSubmit && stateValue !== propsValue) {
      props.onSubmit(state.value, props);
    }

    if (props.onBlur) {
      props.onBlur(state.value, props);
    }
  };

  const handleFocus = () => {
    if (props.autoSelect && inputRef.current) {
      inputRef.current.select();
    }

    if (props.onFocus) {
      props.onFocus(state.value, props);
    }
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      if (props.clearOnSubmit) {
        setState((prevState) => ({
          ...prevState,
          value: '',
          submitted: true
        }));
      }

      let stateValue = state.value;
      let propsValue = props.value;

      if (!state.value && !props.value) {
        stateValue = null;
        propsValue = null;
      }

      if (props.onSubmit && stateValue !== propsValue) {
        props.onSubmit(state.value, props);
      }

      if (props.onEnterPressed) {
        props.onEnterPressed(state.value, props);
      }
    }
  };

  let className = 'r-text-input';

  if (props.className) {
    className += ` ${props.className}`;
  }

  if (state.selected || props.autoFocus) {
    className += ' r-text-input--selected';
  }

  return (
    <>
      {props.label && (
        <label htmlFor={props.name} className="r-text-input-label">
          {props.label}
        </label>
      )}
      {props.isArea ? (
        <textarea
          id={props.id}
          autoComplete="new-password"
          disabled={props.disabled}
          ref={inputRef}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={props.autoFocus}
          className={className}
          name={props.name}
          value={state.value}
          placeholder={props.placeholder}
          onClick={handleSelect}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onKeyDown={handleKeyDown}
          tabIndex={props.tabIndex}
          maxLength={props.maxLength}
          data-testid={props['data-testid']}
          required={props.required}
          aria-label="New password input"
        />
      ) : (
        <input
          id={props.id}
          autoComplete="new-password"
          disabled={props.disabled}
          ref={inputRef}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={props.autoFocus}
          className={className}
          name={props.name}
          value={state.value}
          placeholder={props.placeholder}
          onClick={handleSelect}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onKeyDown={handleKeyDown}
          tabIndex={props.tabIndex}
          type={props.type}
          maxLength={props.maxLength}
          data-testid={props['data-testid']}
          required={props.required}
          aria-label="New password input"
        />
      )}
      {!props.isValid && props.validationMessage && (
        <div className="r-text-input-invalid">{props.validationMessage}</div>
      )}
    </>
  );
}

TextInput.propTypes = {
  autoFocus: PropTypes.bool,
  autoSelect: PropTypes.bool,
  className: PropTypes.string,
  clearOnBlur: PropTypes.bool,
  clearOnSubmit: PropTypes.bool,
  disabled: PropTypes.any,
  // eslint-disable-next-line react/no-unused-prop-types
  groupName: PropTypes.string,
  id: PropTypes.string,
  isArea: PropTypes.bool,
  isValid: PropTypes.bool,
  label: PropTypes.string,
  maxLength: PropTypes.any,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onEnterPressed: PropTypes.func,
  onFocus: PropTypes.func,
  onSubmit: PropTypes.func,
  placeholder: PropTypes.string,
  tabIndex: PropTypes.any,
  required: PropTypes.bool,
  type: PropTypes.string,
  validationMessage: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // eslint-disable-next-line react/no-unused-prop-types
  dataGroup: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  dataField: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  dataType: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  onRef: PropTypes.func,
  // eslint-disable-next-line react/no-unused-prop-types
  dataResourceType: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  dataId: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  disableAutoComplete: PropTypes.bool,
  'data-testid': PropTypes.string
};

export default TextInput;
