// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.r-text-input {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 100%;
  padding: 0;
  border-bottom: solid 1px var(--color-grayscale-4);
}

  .r-text-input:disabled {
    border-bottom: none;
  }

  .r-text-input:focus {
    outline: 0;
  }

  .r-text-input--selected {
    border-color: var(--color-green-6);
  }

  .r-text-input-label {
    color: var(--color-grayscale-6);
    font-size: 12px;
    line-height: 24px;
    font-weight: 500;
  }

  .r-text-input-invalid {
    color: red;
  }
`, "",{"version":3,"sources":["webpack://./app/components/TextInput/style.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,WAAW;EACX,UAAU;EACV,iDAAiD;AAwBnD;;EAtBE;IACE,mBAAmB;EACrB;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,kCAAkC;EACpC;;EAEA;IACE,+BAA+B;IAC/B,eAAe;IACf,iBAAiB;IACjB,gBAAgB;EAClB;;EAEA;IACE,UAAU;EACZ","sourcesContent":[".r-text-input {\n  position: relative;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 24px;\n  width: 100%;\n  padding: 0;\n  border-bottom: solid 1px var(--color-grayscale-4);\n\n  &:disabled {\n    border-bottom: none;\n  }\n\n  &:focus {\n    outline: 0;\n  }\n\n  &--selected {\n    border-color: var(--color-green-6);\n  }\n\n  &-label {\n    color: var(--color-grayscale-6);\n    font-size: 12px;\n    line-height: 24px;\n    font-weight: 500;\n  }\n\n  &-invalid {\n    color: red;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
