import { useState } from 'react';
import PropTypes from 'prop-types';

export default function ToggleGroup(props) {
  const [state, setState] = useState({
    values: [],
    prevValues: null
  });

  if (props.values) {
    state.values = props.values;
  }

  const handleToggle = (id) => {
    const idIndex = state.values.indexOf(id);

    if (props.disableDeselect && idIndex >= 0) {
      return;
    }

    let values = [...state.values];

    if (idIndex >= 0) {
      values.splice(idIndex);
    } else {
      values = [id];
    }

    setState((prevState) => {
      let hasChanged = true;
      const prevValuesLen = prevState.values.length;

      for (let prevValuesIndex = 0; prevValuesIndex < prevValuesLen; prevValuesIndex += 1) {
        const prevValue = prevState.values[prevValuesIndex];

        if (state.values.indexOf(prevValue) === -1) {
          hasChanged = false;
          break;
        }
      }

      const newState = {
        ...prevState,
        values,
        prevValues: state.values
      };

      if (hasChanged && props.onChange) {
        props.onChange(newState, props);
      }

      return newState;
    });
  };

  const handleIsOn = (id) => state.values.indexOf(id) >= 0;

  return props.children({ toggle: handleToggle, isOn: handleIsOn });
}

ToggleGroup.propTypes = {
  className: PropTypes.string,
  values: PropTypes.array,
  disableDeselect: PropTypes.bool,
  onChange: PropTypes.func,
  children: PropTypes.any
};
