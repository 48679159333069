// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.r-tooltip {
  padding: 15px 21px;
  border-radius: 2px;
  background-color: #2a2d30 !important;
  opacity: 1 !important;
  z-index: 9999;
}

  .r-tooltip.place-bottom::after {
      border-bottom-color: #2a2d30 !important;
      border-bottom-width: 9px !important;
    }

  .r-tooltip.place-top::after {
      border-top-color: #2a2d30 !important;
      border-top-width: 9px !important;
    }

  .r-tooltip__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  /* icon */

  .r-tooltip__content-icon {
      width: 14px;
      height: 14px;
      margin-right: 6px;
    }

  /* INFO */

  .r-tooltip__content-icon--info {
        color: var(--color-blue-6);
      }

  /* ERROR */

  .r-tooltip__content-icon--error {
        color: red;
      }

  /* WARN */

  .r-tooltip__content-icon--warn {
        color: yellow;
      }

  /* SUCCESS */

  .r-tooltip__content-icon--success {
        color: #41a741;
      }
`, "",{"version":3,"sources":["webpack://./app/components/Tooltip/style.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,oCAAoC;EACpC,qBAAqB;EACrB,aAAa;AAiDf;;EA9CI;MACE,uCAAuC;MACvC,mCAAmC;IACrC;;EAIA;MACE,oCAAoC;MACpC,gCAAgC;IAClC;;EAGF;IACE,aAAa;IACb,sBAAsB;IACtB,WAAW;IACX,YAAY;EA4Bd;;EA1BE,SAAS;;EACT;MACE,WAAW;MACX,YAAY;MACZ,iBAAiB;IAqBnB;;EAnBE,SAAS;;EACT;QACE,0BAA0B;MAC5B;;EAEA,UAAU;;EACV;QACE,UAAU;MACZ;;EAEA,SAAS;;EACT;QACE,aAAa;MACf;;EAEA,YAAY;;EACZ;QACE,cAAc;MAChB","sourcesContent":[".r-tooltip {\n  padding: 15px 21px;\n  border-radius: 2px;\n  background-color: #2a2d30 !important;\n  opacity: 1 !important;\n  z-index: 9999;\n\n  &.place-bottom {\n    &::after {\n      border-bottom-color: #2a2d30 !important;\n      border-bottom-width: 9px !important;\n    }\n  }\n\n  &.place-top {\n    &::after {\n      border-top-color: #2a2d30 !important;\n      border-top-width: 9px !important;\n    }\n  }\n\n  &__content {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n    height: 100%;\n\n    /* icon */\n    &-icon {\n      width: 14px;\n      height: 14px;\n      margin-right: 6px;\n\n      /* INFO */\n      &--info {\n        color: var(--color-blue-6);\n      }\n\n      /* ERROR */\n      &--error {\n        color: red;\n      }\n\n      /* WARN */\n      &--warn {\n        color: yellow;\n      }\n\n      /* SUCCESS */\n      &--success {\n        color: #41a741;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
